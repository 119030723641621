<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--empty"></div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">

      </div>
      <div class="btn-right-block">
        <MainButton class="btn-fit-content"
                    :value="'Add new'"
                    :ico="'ico'"
                    @click.native="toggleHSCodesPopup(true)"
        >
          <template slot="ico">
            <PlusIconSVG class="btn-icon-plus" />
          </template>
        </MainButton>
      </div>
    </div>

    <HSCodesPopup
        v-if="isModalHSCodesPopup"
        :type="'create'"
        @close="toggleHSCodesPopup(false)"
        @update="$emit('updateTable')"
    />
  </div>
</template>

<script>
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
// import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
// import TableLeftBlockBtn from "../../../../../../coreComponents/TableLeftBlockBtn/TableLeftBlockBtn";
import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
import HSCodesPopup from "../../../../popups/HSCodesPopup/HSCodesPopup";


export default {
  name: "HSCodesHead",
  components: {
    MainButton,
    PlusIconSVG,
    // DefaultCheckbox,
    ToggleFilterButton,
    HSCodesPopup,
    // TableLeftBlockBtn,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
  },


  watch: {

  },

  created() {

  },

  data() {
    return {
      isModalHSCodesPopup: false,
    }
  },

  methods: {
    toggleHSCodesPopup(val){
      this.isModalHSCodesPopup = val
    },

    changeTab(val) {
      this.$emit('changeTab', val)
    },
  }
}
</script>


