<template>
  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-number table-card__item-number--small"
             @click="$emit('showPopup')"
        >
          {{item.code}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          #{{item.id}}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('proform_hSCodeCode.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.code}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('proform_hSCodeNameEl.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
               {{item["proform_name"].currentTranslate.name}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('proform_hSCodeMaterialEl.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
               {{item["proform_material"].currentTranslate.name}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('proform_hSCodeCategory.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
               {{item["proform_category"].currentTranslate.name}}
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    @click.native="$emit('editItem')"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('removeRecord')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";

  export default {
    name: "HSCodesTableMobile",

    components: {
      LinkButton,
    },

    props: {
      item: Object,
    },

    data() {
      return {
        show: false,
      }
    }
  }
</script>

<style scoped>

</style>
