<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      <div
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate([
              'proform_hSCodeCode',
            ])"></span>
        {{$t('proform_hSCodeCode.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="transaction-info__row">
          <div class="transaction-info__col w-100">
            <div class="transaction-info__label">
              {{$t('proform_hSCodeCategory.localization_value.value')}}
            </div>
            <div class="transaction-info__txt">
              {{hSCodeItem["proform_category"].currentTranslate.name}}
            </div>
          </div>
          <div class="transaction-info__col w-100">
            <div class="transaction-info__label">
              {{$t('proform_hSCodeNameEl.localization_value.value')}}
            </div>
            <div class="transaction-info__txt">
              {{hSCodeItem["proform_name"].translationStorage.ua.name}}
            </div>
          </div>
          <div class="transaction-info__col w-100">
            <div class="transaction-info__label">
              {{$t('proform_hSCodeMaterialEl.localization_value.value')}}
            </div>
            <div class="transaction-info__txt">
              {{hSCodeItem["proform_material"].translationStorage.ua.name}}
            </div>
          </div>
          <div class="transaction-info__col w-100">
            <div class="transaction-info__label">
              {{$t('proform_hSCodeCode.localization_value.value')}}
            </div>
            <div class="transaction-info__txt">
              {{hSCodeItem.code}}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns">

            <span class="site-link site-link--alt mr-3 mb-2 mt-2"
                  @click="$emit('close')"
            >
              {{$t('common_cancel.localization_value.value')}}
            </span>

      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'

  export default {
    name: "HSCodeInfoPopup",
    components: {
      Modal,
    },

    props: {
      type: String,
      hSCodeItem: Object,
    },

    data() {
      return {

      }
    },

    created(){

    },

    methods: {

    }
  }

</script>

<style scoped>
  .scoped-width{
    max-width: 120px;
  }

  .scoped-btns{
    display: flex;
    align-items: center;
  }
</style>
