<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['proform_hSCodeId'])"></span>
        <DefaultInput
            :label="$t('proform_hSCodeId.localization_value.value')"
            :type="'text'"
            v-model="hSCodeId"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['proform_hSCodeCode'])"></span>
        <DefaultInput
            :label="$t('proform_hSCodeCode.localization_value.value')"
            :type="'text'"
            v-model="hSCodeCode"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['proform_hSCodeNameEl'])"></span>
        <DefaultInput
            :label="$t('proform_hSCodeNameEl.localization_value.value')"
            :type="'text'"
            v-model="hSCodeName"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['proform_hSCodeMaterialEl'])"></span>
        <DefaultInput
            :label="$t('proform_hSCodeMaterialEl.localization_value.value')"
            :type="'text'"
            v-model="hSCodeMaterial"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['proform_hSCodeCategoryEl'])"></span>

        <DefaultSelect
            :options="$store.getters.getProformCategory"
            :label="$t('proform_hSCodeCategory.localization_value.value')"
            :otherValue="'proformData'"
            :selected="getCategory()"
            @change="changeCategory"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "HSCodesFilter",
    components: {
      FilterBlock,
      MainButton,
      DefaultInput,
      DefaultSelect,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        hSCodeId: this.filterGetParams.hSCodeId ? this.filterGetParams.hSCodeId : '',
        hSCodeCode: this.filterGetParams.hSCodeCode ? this.filterGetParams.hSCodeCode : '',
        hSCodeName: this.filterGetParams.hSCodeName ? this.filterGetParams.hSCodeName : '',
        hSCodeMaterial: this.filterGetParams.hSCodeMaterial ? this.filterGetParams.hSCodeMaterial : '',
        hSCodeCategory: this.filterGetParams.hSCodeCategory ? this.filterGetParams.hSCodeCategory : '',
        hSCodeCategoryId: this.filterGetParams.hSCodeCategoryId ? this.filterGetParams.hSCodeCategoryId : '',

        options: [],

        filterCounts: [
          'hSCodeId',
          'hSCodeCode',
          'hSCodeName',
          'hSCodeMaterial',
          'hSCodeCategory',
          'hSCodeCategoryId',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.hSCodeId = newVal.hSCodeId ? newVal.hSCodeId : ''
        this.hSCodeCode = newVal.hSCodeCode ? newVal.hSCodeCode : ''
        this.hSCodeName = newVal.hSCodeName ? newVal.hSCodeName : ''
        this.hSCodeMaterial = newVal.hSCodeMaterial ? newVal.hSCodeMaterial : ''
        this.hSCodeCategory = newVal.hSCodeCategory ? newVal.hSCodeCategory : ''
        this.hSCodeCategoryId = newVal.hSCodeCategoryId ? newVal.hSCodeCategoryId : ''
      },

    },

    created() {
      this.$store.dispatch('fetchProformCategory').then(() => {
        this.getCategory()
      })
    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'options', loading)
      },

      changeCategory(value){
        this.hSCodeCategoryId = value.id
      },

      getCategory(){
        if(this.hSCodeCategoryId === '') return ''
        return this._.find(this.$store.getters.getProformCategory, {id: parseInt(this.hSCodeCategoryId)})
      },

    },
  }
</script>

<style scoped>

</style>
