<template>
  <div>
    <HSCodesAdmin
        @showMore="showMore"
        :countFilterParams="countFilterParams"
        @changeFilter="changeFilter"
        @resetFilter="resetFilter"
        :filterGetParams="filterGetParams"
        @updateTable="filter"
        v-if="$store.getters.getIsAdminRights === 'admin'"
    />

  </div>
</template>

<script>


import HSCodesAdmin from "./HSCodesAdmin/HSCodesAdmin";
import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

export default {
  name: "HSCodesTable",

  components: {
    HSCodesAdmin,
  },

  mixins: [routeFilter],

  data(){
    return {
      forPage: this.$store.getters.getHSCodesForPage,
      page: 1,
      onePage: 1,

      filterGetParams:{},

      filterHSCodeId: '',
      filterHSCodeCode: '',
      filterHSCodeName: '',
      filterHSCodeMaterial: '',
      filterHSCodeCategory: '',
      filterHSCodeCategoryId: '',

      countFilterParams: 0,
    }
  },

  created() {
    this.filter()
  },

  methods: {

    getFilterParam() {
      this.filterGetParams = {
        page: this.$route.query.page,
        count: this.$route.query.count,
        hSCodeId: this.$route.query.hSCodeId,
        hSCodeCode: this.$route.query.hSCodeCode,
        hSCodeName: this.$route.query.hSCodeName,
        hSCodeMaterial: this.$route.query.hSCodeMaterial,
        hSCodeCategory: this.$route.query.hSCodeCategory,
        hSCodeCategoryId: this.$route.query.hSCodeCategoryId,
      }
    },

    refreshTable() {
      this.filter()
    },

    filter(next = false) {

      this.getFilterParam()

      let forPage, page, url

      // generate main filter if has get params in URL
      let generateMainFilter = this.generateMainFilterFromUrl(next, this)

      page = generateMainFilter.page
      forPage = generateMainFilter.forPage

      // generate other filter if has get params in URL
      this.generateParamsFilterFromUrl(this)

      // Generate filter URL
      url = this.generateFilterUrl(page, forPage)

      // True if get next page
      if (next) this.$store.commit('setNextHSCodesPage', true)

      // save filter params in store
      this.$store.commit('setHSCodesFilter', window.location.search)
      this.$store.dispatch('fetchHSCodes', url).then(() => {
        // skip get next page
        this.$store.commit('setNextHSCodesPage', false)
      })

      this.checkCountFilter()

    },

    generateFilterUrl(page, forPage) {
      let myQuery = this.createQueryBuilderObject()

      this.generateFilterQueryParams(
          myQuery,
          {
            filterHSCodeId: 'HscodeId',
            filterHSCodeCode: 'HscodeCode',
            filterHSCodeName: 'proformNameEn',
            filterHSCodeMaterial: 'proformMaterial',
            filterHSCodeCategoryId: 'HscodeCategoryId',
          },
      )

      // myQuery.where('HSCodesIsSystem', '0')

      return this.generateGetParamsFromQuery(myQuery, forPage, page)


    },

  }
}
</script>

<style scoped>

</style>
