<template>
  <div>

    <div class="error-page-block d-flex flex-column align-items-center" v-if="isNotFound">
      <NotFoundImg/>

      <p v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_notFoundTxt'])"></span>
        {{$t('common_notFoundTxt.localization_value.value')}}
      </p>

      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_goToDashboard'])"></span>
        <router-link :to="$store.getters.GET_PATHS.dashboard">
          <MainButton
                  class="wfc"
                  :value="$t('common_goToDashboard.localization_value.value')"
          />
        </router-link>
      </div>

    </div>

    <!--<div v-show="checking">{{$t('common_checking.localization_value.value')}}</div>-->
  </div>
</template>

<script>
  import NotFoundImg from '../../../../public/img/common/404-img.svg?inline'
  import MainButton from "../../UI/buttons/MainButton/MainButton";

  export default {
    name: "NotFoundModule",

    components: {
      MainButton,
      NotFoundImg,
    },

    data() {
      return {
        checking: false,
        isNotFound: false,
      }
    },

    created() {
      this.checking = true
      // this.$store.dispatch('fetchCurrentUserRights').then(() => {
      //   if (window.location.href.indexOf('dashboard') !== -1){
      //     if (
      //           this.isAdmin ||
      //           this.$store.getters.getUserProfile.isAdmin
      //     ){
      //       let url = '/admin' + window.location.pathname
      //       window.location.search.length > 0 ? url = url + window.location.search : false
      //       this.$router.push(url)
      //     } else {
      //       let url = window.location.pathname
      //       url = url.replace('/admin/', '')
      //       this.$router.push('/' + url)
      //     }
      //   } else {
      //     this.isNotFound = true
      //   }
      // })



      this.$store.dispatch('fetchCurrentUserRights').then(() => {


        if (window.location.href.indexOf('/admin/') === -1) {
          if (
            this.isAdmin ||
            this.$store.getters.getUserProfile.isAdmin
          ) {
            let url = '/admin' + window.location.pathname
            window.location.search.length > 0 ? url = url + window.location.search : false
            this.$router.push(url)
          } else {
            this.isNotFound = true
          }
        } else {
          this.isNotFound = true
        }
        // else if (window.location.href.indexOf('/admin/') > -1) {
        //   if (
        //     !this.isAdmin &&
        //     !this.$store.getters.getUserProfile.isAdmin
        //   ) {
        //     let url = window.location.pathname
        //     url = url.replace('/admin/', '')
        //     this.$router.push('/' + url)
        //   } else {
        //     this.isNotFound = true
        //   }
        // }

        this.checking = false
      })


    },

  }
</script>

<style scoped>

</style>
