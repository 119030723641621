<template>
  <div>
    <HSCodesHead
        :countFilterParams="countFilterParams"
        :showFilter="showFilter"
        @toggleFilter="toggleFilter"
        @updateTable="$emit('updateTable')"
    />

    <div class="table-filter-wrap">

      <HSCodesFilter
          :showFilter="showFilter"
          @close="closeFilter"
          @changeFilter="changeFilter"
          @resetFilter="$emit('resetFilter')"
          :filterGetParams="filterGetParams"
      />

      <HSCodesTable
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @updateTable="$emit('updateTable')"
          :countFilterParams="countFilterParams"
      />
    </div>
  </div>
</template>

<script>


import HSCodesHead from "./HSCodesHead/HSCodesHead";
import HSCodesTable from "./HSCodesTable/HSCodesTable";
import HSCodesFilter from "./HSCodesFilter/HSCodesFilter";

export default {
  name: "HSCodesTableAdmin",

  components: {
    HSCodesHead,
    HSCodesTable,
    HSCodesFilter,
  },

  props: {
    countFilterParams: Number,
    filterGetParams: Object,
  },

  data(){
    return{
      showFilter: false,
      selectedNow: false,
    }
  },

  methods: {
    closeFilter() {
      this.showFilter = false
    },

    toggleFilter() {
      this.showFilter = !this.showFilter
    },

    changeShopType(index) {
      this.$emit('changeShopType', index)
    },


    changeFilter(data) {
      this.$emit('changeFilter', data)
    },


    selectAll(val) {
      this.selectedNow = val
    },
  }
}
</script>

<style scoped>

</style>
