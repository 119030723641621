<template>
  <div class="page-content">
    <router-view></router-view>
  </div>
</template>

<script>

  export default {
    name: "GetFileModule",

    data(){
      return {

      }
    },

  }
</script>

<style scoped>

</style>
