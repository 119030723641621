<template>
  <div class="fragment">
    <template v-if="$store.getters.getHSCodes.length > 0 && $store.getters.getHSCodesLoading !== true">
      <div class="site-table-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'proform_hSCodeId',
          'proform_hSCodeCode',
          'proform_hSCodeNameEl',
          'proform_hSCodeMaterialEl',
          'proform_hSCodeCategory',
          'common_manage',
        ])"></span>
        <div class="site-table-wrap small-table">
          <table class="site-table" v-if="!isMobileFunc()">
            <thead>
            <tr>
              <th>{{$t('proform_hSCodeId.localization_value.value')}}</th>
              <th>{{$t('proform_hSCodeCode.localization_value.value')}}</th>
              <th>{{$t('proform_hSCodeNameEl.localization_value.value')}}</th>
              <th>{{$t('proform_hSCodeMaterialEl.localization_value.value')}}</th>
              <th>{{$t('proform_hSCodeCategory.localization_value.value')}}</th>
              <th width="100%"></th>
              <th>{{$t('common_manage.localization_value.value')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in $store.getters.getHSCodes"
                :key="index"
            >
              <td>
<!--                <span class="table-link btn-style"  @click="editItem(item.id)">-->
                <span class="table-link btn-style"  @click="showItem(item)">
                  #{{item.id}}
                </span>
              </td>
              <td>{{item.code}}</td>

              <td>
                {{item["proform_name"].translationStorage.ua.name}}
              </td>
              <td>
                {{item["proform_material"].translationStorage.ua.name}}
              </td>
              <td>
                {{item["proform_category"].currentTranslate.name}}
              </td>

              <td width="100%">
              </td>
              <td>
                <div class="table-right table-manage-list">
                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          @click.native="editItem(item.id)"
                      />
                      <LinkButton
                          :value="$t('common_delete.localization_value.value')"
                          :type="'delete'"
                          @click.native="removeRecord(item.id, index)"
                      />
                    </template>
                  </ManagerButton>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="table-card" v-else>
            <div class="table-card__list">
              <div class="table-card__item"
                   v-for="(item, index) in $store.getters.getHSCodes"
                   :key="index"
              >
                <HSCodesTableMobile
                    :item="item"
                    @editItem="editItem(item.id)"
                    @removeRecord="removeRecord(item.id, index)"
                />
              </div>
            </div>
          </div>

          <div class="table-bottom-btn" >
            <div class="table-bottom-btn__inner">
              <ShowMore
                  v-if="$store.getters.getHSCodesCommonList.next_page_url !== null && $store.getters.getHSCodes.length > 0"
                  @click.native="$emit('showMore')"
                  v-bind:class="{'disabled-btn' : $store.getters.getNextHSCodesPage}"
                  :count="$store.getters.getHSCodesCommonList.total - $store.getters.getHSCodesForPage * $store.getters.getHSCodesCommonList.current_page < $store.getters.getHSCodesForPage ?
                  $store.getters.getHSCodesCommonList.total - $store.getters.getHSCodesForPage * $store.getters.getHSCodesCommonList.current_page:
                  $store.getters.getHSCodesForPage"
              />
            </div>
          </div>
        </div>



      </div>
    </template>

    <template v-if="$store.getters.getHSCodesLoading === false && $store.getters.getHSCodes.length === 0">
      <NoResult
          class="pt-3"
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>


    <HSCodesPopup
        v-if="hSCodesPopup"
        :type="'update'"
        @close="toggleHSCodesPopup(false)"
        @update="$emit('updateTable')"
    />

    <HSCodeInfoPopup
        v-if="hSCodeInfoPopup"
        @close="toggleHSCodeInfoPopup(false)"
        :hSCodeItem="hSCodeItem"
    />
  </div>
</template>

<script>
import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
import HSCodesPopup from "../../../../popups/HSCodesPopup/HSCodesPopup";
import HSCodeInfoPopup from "../../../../popups/HSCodeInfoPopup/HSCodeInfoPopup";
import HSCodesTableMobile from "./HSCodesTableMobile/HSCodesTableMobile";


export default {
  name: "HSCodesTable",

  components: {
    ShowMore,
    LinkButton,
    ManagerButton,
    NoResult,
    HSCodesPopup,
    HSCodeInfoPopup,
    HSCodesTableMobile,
  },

  mixins: [mixinDetictingMobile],

  // mounted() {
  //   this.isMobile = this.isMobileFunc
  //
  //   window.addEventListener('resize', () => {
  //     this.isMobile = this.isMobileFunc
  //   })
  // },

  props: {
    selectedNow: Boolean,
    countFilterParams: Number,
  },

  data() {
    return {
      orderId: '',
      hSCodesPopup: false,
      hSCodeInfoPopup: false,
      hSCodeItem: {},
    }
  },

  mounted() {

  },

  methods: {

    toggleHSCodesPopup(val){
      this.hSCodesPopup = val
    },

    toggleHSCodeInfoPopup(val){
      this.hSCodeInfoPopup = val
    },

    editItem(id) {
      this.$store.dispatch('getHSCo' +
        'des', id).then(() => {
        this.toggleHSCodesPopup(true)
      })
    },

    showItem(item){
      this.hSCodeItem = item
      this.toggleHSCodeInfoPopup(true)
    },

    removeRecord(id, index) {
      let text = {
        title: 'common_deleteConfirm',
        txt: 'proform_deleteHSCode',
        yes: 'common_confirm',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('deleteHSCodes', id).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            let records = this.$store.getters.getHSCodes
            records.splice(index, 1)
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)

    },

  }

}
</script>

<style lang="scss" scoped>
  .site-table tbody td {
    padding-top: 15px;
    padding-bottom: 15px;
  }
</style>
