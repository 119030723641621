<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      <div
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate([
              'proform_createHSCode',
              'proform_editHSCode',
            ])"></span>
        <template v-if="type === 'create'">
          {{$t('proform_createHSCode.localization_value.value')}}
        </template>
        <template v-else>
          {{$t('proform_editHSCode.localization_value.value')}}
        </template>
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <div class="transaction-info__section">

          <div class="custom-row">
            <div class="custom-col">
              <DefaultSelect
                :options="$store.getters.getProformCategory"
                :label="$t('proform_hSCodeCategory.localization_value.value')"
                :otherValue="'proformData'"
                v-bind:class="{'ui-no-valid': hSCode.validation.category}"
                :errorTxt="serverError ? hSCode.validationTxt.category : $t(`${hSCode.validationTranslate.category}.localization_value.value`)"
                :error="hSCode.validation.category"
                :selected="hSCode.data.category"
                @change="changeCategory"
              />
            </div>

            <div class="custom-col" v-if="hSNames.length > 0 || type === 'update'">
              <DefaultSelect
                  :options="hSNames"
                  :label="$t('proform_hSCodeNameEl.localization_value.value')"
                  :otherValue="'proformHSCode'"
                  v-bind:class="{'ui-no-valid': hSCode.validation.name}"
                  :errorTxt="serverError ? hSCode.validationTxt.name : $t(`${hSCode.validationTranslate.name}.localization_value.value`)"
                  :error="hSCode.validation.name"
                  :selected="hSCode.data.name"
                  @change="changeName"
              />
            </div>

            <div class="custom-col" v-if="hSMaterials.length > 0 || type === 'update'">
              <DefaultSelect
                  v-if="type === 'update'"
                  :options="hSMaterials"
                  :label="$t('proform_hSCodeMaterialEl.localization_value.value')"
                  :otherValue="'proformHSCode'"
                  v-bind:class="{'ui-no-valid': hSCode.validation.material}"
                  :errorTxt="serverError ? hSCode.validationTxt.material : $t(`${hSCode.validationTranslate.material}.localization_value.value`)"
                  :error="hSCode.validation.material"
                  :selected="hSCode.data.material"
                  @change="changeMaterial"
              />

              <MultiSelect
                  v-if="type !== 'update'"
                  :options="hSMaterials"
                  :label="$t('proform_hSCodeMaterialEl.localization_value.value')"
                  :otherValue="'proformHSCode'"
                  v-bind:class="{'ui-no-valid': hSCode.validation.material}"
                  :errorTxt="serverError ? hSCode.validationTxt.material : $t(`${hSCode.validationTranslate.material}.localization_value.value`)"
                  :error="hSCode.validation.material"
                  :selected="hSCode.data.material"
                  @change="changeMaterial"
              />
            </div>
            <div class="custom-col" v-if="hSCode.data.material || type === 'update'">
              <DefaultInput
                  :label="$t('proform_hSCodeCode.localization_value.value') + (hSCode.data.countryGroupId === 13 ? ' USA' : ' EU')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': hSCode.validation.code}"
                  :errorTxt="serverError ? hSCode.validationTxt.code : $t(`${hSCode.validationTranslate.code}.localization_value.value`)"
                  :error="hSCode.validation.code"
                  v-model="hSCode.data.code"
              />
            </div>
            <div class="custom-col" v-if="hSCode.data.material && type !== 'update'">
              <DefaultInput
                  :label="$t('proform_hSCodeCode.localization_value.value') + ' USA'"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': hSCode.validation.codeUSA}"
                  :errorTxt="serverError ? hSCode.validationTxt.codeUSA : $t(`${hSCode.validationTranslate.codeUSA}.localization_value.value`)"
                  :error="hSCode.validation.codeUSA"
                  v-model="hSCode.data.codeUSA"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns">

            <span class="site-link site-link--alt mr-3 mb-2 mt-2"
                  @click="$emit('close')"
            >
              {{$t('common_cancel.localization_value.value')}}
            </span>

        <template v-if="type === 'create'">
          <MainButton
              class="scoped-width wfc"
              :value="$t('common_create.localization_value.value')"
              @click.native="handlerHSCodes"
          />
        </template>
        <template v-else>
          <MainButton
              class="scoped-width wfc"
              :value="$t('common_save.localization_value.value')"
              @click.native="handlerHSCodes"
          />
        </template>

      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {HSCode} from "../../models/HSCode";
  import MultiSelect from "@/components/UI/selectiones/MultiSelect/MultiSelect";


  export default {
    name: "HSCodesPopup",
    components: {
      MultiSelect,
      Modal,
      MainButton,
      DefaultInput,
      DefaultSelect,
    },

    props: {
      type: String,
    },

    data() {
      return {
        name: [],
        created: false,
        sortCount: '',

        hSCode: new HSCode(),

        serverError: false,

        options: [],

        hSCodes: [],
        hSNames: [],
        hSMaterials: [],
      }
    },

    created(){
      this.$store.dispatch('fetchProformCategory', '?filter[exclude_system_categories]=1')

      if (this.type === 'update'){
        this.hSCode.setHSCodeItem(this.$store.getters.getHSCodesItem)
        this.getHSCodesFromType(this.hSCode.getCategory(), 1)
        this.getHSCodesFromType(
            this.hSCode.getCategory(),
            2,
            this.hSCode.getName().proform_value.id
        )
      }
    },

    methods: {
      handlerHSCodes() {

        if(!this.hSCode.firstValidation({codeUSA: this.type === 'create'})) return
        let data = this.hSCode.prepareData(this.type === 'update')

        if (this.type === 'create') {
          this.createItem(data)
        } else {
          this.changeItem(data, this.$store.getters.getHSCodesItem.id)
        }
      },

      createItem(data) {
        this.$store.dispatch('createHSCodes', data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordCreated')
              this.$emit('update')
              this.$emit('close')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              console.log(response.data.errors);
              let errors = response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      changeItem(data, id) {
        this.$store.dispatch('updateHSCodes', {data: data, id: id}).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('update')
              this.$emit('close')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      changeCategory(val) {
        this.hSNames = []
        this.hSMaterials = []
        if(val === '') {
          this.hSCode.setCategory('')
          return
        }
        this.hSCode.setCategory(val)
        this.getHSCodesFromType(val, 1)
      },

      getHSCodesFromType(val, type, nameId = false) {
        const query = new this.Query();
        let url = '?';
        let myQuery = query
            .for('posts')

        switch (type) {
          case 1:
            //change category
            myQuery.where('ProformAttachmentProformCategoryId', val.id)
            myQuery.where('ProformAttachmentProformTypeId', type)
            myQuery.where('exclude_hs_codes_by_naming', 1)
            break
          case 2:
            //change name
            myQuery.where('ProformAttachmentProformCategoryId', val.id)
            myQuery.where('ProformAttachmentProformTypeId', type)
            myQuery.where('exclude_hs_by_naming_materials', nameId)
            break
        }

        url = url + myQuery.limit(100000).page(1).url().split('?')[1]

        this.$store.dispatch('getHSCodesFromCategoryId', url).then((response) => {
          switch (type) {
            case 1:
              this.hSNames = response
              break
            case 2:
              this.hSMaterials = response
              if(this.type === 'create') {
                this.hSCode.data.material = response
              }
              break
          }
        })
      },

      changeName(val){
        this.hSMaterials = []
        if(val === '') {
          this.hSCode.setName('')
          return
        }
        this.hSCode.setName(val)
        this.getHSCodesFromType(this.hSCode.getCategory(), 2, val["proform_value"].id)
      },

      changeMaterial(val){
        this.hSCode.setMaterial(val)
      },
    }
  }

</script>

<style scoped>
  .scoped-width{
    max-width: 120px;
  }

  .scoped-btns{
    display: flex;
    align-items: center;
  }
</style>
